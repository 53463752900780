import React from "react";
import Helmet from "react-helmet";
import { graphql } from "gatsby";
import Content, { HTMLContent } from "../components/Content";
import Page from "../components/page";
import Section from "../components/section";
import FullWidthImage from "../components/FullWidthImage";
import { Title, SubTitle } from "../components/text";

export const BlogPostTemplate = ({
  content,
  contentComponent,
  description,
  title,
  date,
  image,
  helmet,
}) => {
  const PostContent = contentComponent || Content;
  // console.warn(image)
  return (
    <Page>
      {helmet || ""}
      <FullWidthImage image={image} height="50vh" parallax={50} dim={0.6}>
        <Title bright marginBottom>
          {title}
        </Title>
        <SubTitle bright>{date}</SubTitle>
      </FullWidthImage>
      <Section>
        <PostContent content={content} className="content" />
      </Section>
    </Page>
  );
};

export default (props) => {
  const { markdownRemark: post } = props.data;

  return (
    <BlogPostTemplate
      content={post.html}
      contentComponent={HTMLContent}
      description={post.frontmatter.description}
      helmet={<Helmet title={`Wesmans | News | ${post.frontmatter.title}`} />}
      title={post.frontmatter.title}
      date={post.frontmatter.date}
      image={post.frontmatter.image}
    />
  );
};

export const pageQuery = graphql`
  query BlogPostByID($id: String!) {
    markdownRemark(id: { eq: $id }) {
      id
      html
      frontmatter {
        date(formatString: "MMMM DD, YYYY")
        title
        image {
          childImageSharp {
            fluid(maxWidth: 2000, quality: 64) {
              ...GatsbyImageSharpFluid
            }
          }
        }
        description
      }
    }
  }
`;
